html, body, #root {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  font-family: GoogleSans, Helvetica, Arial, sans-serif;
  user-select: none;
  background-color: #000;
}

input[type='text'] {
  width: 100%;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: GoogleSans;
}

input:focus {
  outline: none;
}

input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #dedede;
  background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #ffffff),
      color-stop(100%, #ffffff)
  );
  background-size: 50% 100%;
  background-repeat: no-repeat;
  border-radius: 24px;
  border: solid 1px rgb(231, 231, 231);
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 1);
}

input[type='range'].small-thumb::-webkit-slider-thumb {
  height: 18px !important;
  width:  18px !important;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.hidden-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}


/* ================================= LIST  ================================== */
ul, ol {
  margin: 0;
}

table, tr, td {
  border: 1px solid #969696;
  border-collapse: collapse;
}

td {
  padding: 8px 12px;
}

td:first-child {
  min-width: 140px;
}

/* ============================ VERTICAL SLIDER  ============================ */
input[type='range'].v-slider {
  height: 24px;
  width: 140px;
  transform-origin: 0 0;
  margin-top: 140px;
  margin-left: 11px;
  transform: rotate(-90deg);
}

.ghost {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}

.z-0 { z-index: 0; }
.z-1 { z-index: 1; }
.z-2 { z-index: 2; }
.z-3 { z-index: 3; }
.z-4 { z-index: 4; }
.z-10 { z-index: 10; }
.z-11 { z-index: 11; }
.z-dialog-bg { z-index: 12; }
.z-dialog { z-index: 13; }
.z-alert { z-index: 14; }

.abs { position: absolute; }
.rel { position: relative; }
.fixed { position: fixed; }
.sticky { position: sticky; position: -webkit-sticky; }

.border-box { box-sizing: border-box; }

.overflow-hidden { overflow: hidden; }
.overflow-scroll { overflow: scroll; }
.overflow-wrap { overflow: wrap; }
.overflow-auto { overflow: auto; }
.overflow-y-scroll { overflow-y: scroll; }
.overflow-y-auto { overflow-y: auto; }
.overflow-x-hidden { overflow-x: hidden; }

.float-l { float: left; }
.float-r { float: right; }

.drag { -webkit-app-region: drag; }

.no-drag { -webkit-app-region: no-drag; }

.object-fit-contain { object-fit: contain; }
.object-fit-cover { object-fit: cover; }
.object-fit-scale-down { object-fit: scale-down; }

.text-center { text-align: center; }
.text-right { text-align: right; }

.text-vertical-middle { vertical-align: middle; }

.inline-block { display: inline-block; }

.rotate-90-c { transform: rotate(90deg); }
.rotate-90-ac { transform: rotate(-90deg); }
.rotate-180 { transform: rotate(180deg); }

/* STATE LAYER ============================================================== */
.off-state { opacity: 0%; }
.hover-state { opacity: 8%; }
.focus-state { opacity: 12%; }
.press-state { opacity: 12%; }
.disabled-content { opacity: 38%; }
.disabled-container { opacity: 12%; }

/* ALIGN ==================================================================== */
.inset-0 { inset: 0; }

.left-0 { left: 0; }
.left-50 { left: 50%; }
.left-40 { left: 40%; }
.left-10px { left: 10px; }
.left-12px { left: 12px; }
.left-24px { left: 24px; }
.left-64px { left: 64px; }

.right-0 { right: 0; }
.right-6px { right: 6px; }
.right-12px { right: 12px; }
.right-24px { right: 24px; }
.right-64px { right: 64px; }

.top-0 { top: 0; }
.top-50 { top: 50%; }
.top-4px { top: 4px; }
.top-10px { top: 10px; }
.top-12px { top: 12px; }
.top-24px { top: 24px; }
.top-75px { top: 75px; }
.top-90px { top: 90px; }
.top-240px { top: 240px; }

.bottom-0 { bottom: 0px; }
.bottom-80 { bottom: 80px !important; /* Needs important to override bottom-0 for animation */ }
.bottom-6px { bottom: 6px; }
.bottom-12px { bottom: 12px; }
.bottom-20px { bottom: 20px; }
.bottom-24px { bottom: 24px; }
.bottom-64px { bottom: 64px; }

/* WIDTH AND HEIGHT ========================================================= */
.w-0 { width: 0; }
.w-10 { width: 10%; }
.w-20 { width: 20%; }
.w-25 { width: 25%; }
.w-30 { width: 30%; }
.w-40 { width: 40%; }
.w-50 { width: 50%; }
.w-55 { width: 55%; }
.w-60 { width: 60%; }
.w-70 { width: 70%; }
.w-80 { width: 80%; }
.w-90 { width: 90%; }
.w-95 { width: 95%; }
.w-100 { width: 100%; }
.w-100vw { width: 100vw; }
.w-1px { width: 1px; }
.w-12px { width: 12px; }
.w-18px { width: 18px; }
.w-24px { width: 24px; }
.w-36px { width: 36px; }
.w-40px { width: 40px; }
.w-42px { width: 42px; }
.w-64px { width: 64px; }
.w-70px { width: 70px; }
.w-100px { width: 100px; }
.w-105px { width: 105px; }
.w-125px { width: 125px; }
.w-fit-content { width: fit-content; }
.w-max-content { width: max-content; }
.w-inherit { width: inherit; }
.w-auto { width: auto; }

.h-10 { height: 10%; }
.h-30 { height: 30%; }
.h-35 { height: 35%; }
.h-40 { height: 40%; }
.h-50 { height: 50%; }
.h-60 { height: 60%; }
.h-65 { height: 65%; }
.h-80 { height: 80%; }
.h-90 { height: 90%; }
.h-100 { height: 100%; }
.h-100vh { height: 100vh; }
.h-8px { height: 8px; }
.h-12px { height: 12px; }
.h-18px { height: 18px; }
.h-24px { height: 24px; }
.h-26px { height: 26px; }
.h-32px { height: 32px; }
.h-36px { height: 36px; }
.h-40px { height: 40px; }
.h-42px { height: 42px; }
.h-56px { height: 56px; }
.h-64px { height: 64px; }
.h-70px { height: 70px; }
.h-fit-content { height: fit-content; }
.h-inherit { height: inherit; }
.h-auto { height: auto; }

.size-sm { height: 16px; width: 16px; }
.size-md { height: 24px; width: 24px; }

.aspect-16-9 { aspect-ratio: 16/9; }
.padding-bottom-16-9 { padding-bottom: 56.25%; }

/* ROUNDED ===================================================================*/
.rounded-xsm { border-radius: 4px; }
.rounded-sm { border-radius: 8px; }
.rounded-md { border-radius: 16px; }
.rounded-lg { border-radius: 24px; }
.rounded-xlg { border-radius: 36px; }
.rounded-full {	border-radius: 9999px; }
.rounded-r-lg {
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
.rounded-t-sm {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.rounded-b-sm {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.rounded-0 { border-radius: 0px; }
.rounded-l-0 {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.rounded-r-0 {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.rounded-b-0 {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

/* BORDERS ===================================================================*/
.border-0 { border-style: hidden; }
.border-1 { border-width: 1px; }
.border-2 { border-width: 2px; }

.border-bottom-1 {
  border: none;
  border-bottom: 1px solid;
}

.border-solid { border-style: solid; }

.border-white-hover:hover {
  border-color: #DCE2FA;
}
.outline-0 { outline: none; }

/* MARGIN ====================================================================*/
.margin-l-xsm { margin-left: 4px; }
.margin-l-sm { margin-left: 8px; }
.margin-l-md { margin-left: 12px; }
.margin-l-lg { margin-left: 24px; }
.margin-l-xlg { margin-left: 42px; }

.margin-t-xxsm { margin-top: 2px; }
.margin-t-xsm { margin-top: 4px; }
.margin-t-sm { margin-top: 8px; }
.margin-t-md { margin-top: 16px; }
.margin-t-lg { margin-top: 24px; }
.margin-t-xlg { margin-top: 42px; }

.margin-r-xsm { margin-right: 4px; }
.margin-r-sm { margin-right: 8px; }
.margin-r-md { margin-right: 12px; }
.margin-r-lg { margin-right: 24px; }
.margin-r-xlg { margin-right: 42px; }

.margin-b-xsm { margin-bottom: 4px; }
.margin-b-sm { margin-bottom: 8px; }
.margin-b-md { margin-bottom: 16px; }
.margin-b-lg { margin-bottom: 24px; }
.margin-b-xlg { margin-bottom: 42px; }

.margin-h-xsm { margin-top: 4px; margin-bottom: 4px; }
.margin-h-sm { margin-top: 8px; margin-bottom: 8px; }
.margin-h-md { margin-top: 12px; margin-bottom: 12px; }
.margin-h-lg { margin-top: 16px; margin-bottom: 16px; }

.margin-w-xxsm { margin-left: 2px; margin-right: 2px; }
.margin-w-xsm { margin-left: 4px; margin-right: 4px; }
.margin-w-sm { margin-left: 8px; margin-right: 8px; }
.margin-w-md { margin-left: 12px; margin-right: 12px; }

.margin-w-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-h-auto {
  margin-top: auto;
  margin-bottom: auto;
}

/* PADDING ===================================================================*/
.padding-0 { padding: 0; }
.padding-xsm { padding: 4px; }
.padding-sm { padding: 4px 8px; }
.padding-md { padding: 8px 12px; }
.padding-lg { padding: 12px 24px; }
.padding-xlg { padding: 32px; }

.padding-l-0 { padding-left: 0; }
.padding-l-xxsm { padding-left: 2px; }
.padding-l-xsm { padding-left: 4px; }
.padding-l-sm { padding-left: 8px; }
.padding-l-md { padding-left: 16px; }
.padding-l-lg { padding-left: 24px; }

.padding-t-xsm { padding-top: 8px; }
.padding-t-sm { padding-top: 12px; }
.padding-t-md { padding-top: 16px; }
.padding-t-lg { padding-top: 24px; }

.padding-b-0 { padding-bottom: 0px; }
.padding-b-xxsm { padding-bottom: 2px; }
.padding-b-sm { padding-bottom: 8px; }
.padding-b-md { padding-bottom: 16px; }
.padding-b-lg { padding-bottom: 24px; }

.padding-r-xsm { padding-right: 4px; }
.padding-r-sm { padding-right: 8px; }
.padding-r-md { padding-right: 16px; }
.padding-r-lg { padding-right: 24px; }

.padding-h-0 { padding-top: 0px; padding-bottom: 0px; }
.padding-h-xsm { padding-top: 4px; padding-bottom: 4px; }
.padding-h-sm { padding-top: 8px; padding-bottom: 8px; }
.padding-h-md { padding-top: 16px; padding-bottom: 16px; }
.padding-h-lg { padding-top: 20px; padding-bottom: 20px; }
.padding-h-xlg { padding-top: 24px; padding-bottom: 24px; }

.padding-w-xsm { padding-left: 4px; padding-right: 4px; }
.padding-w-sm { padding-left: 8px; padding-right: 8px; }
.padding-w-md { padding-left: 16px; padding-right: 16px; }
.padding-w-lg { padding-left: 24px; padding-right: 24px; }
.padding-w-xlg { padding-left: 32px; padding-right: 32px }
.padding-w-xxlg { padding-left: 64px; padding-right: 64px; }

/* FLEX ======================================================================*/
.flex { display: flex; }
.flex-inline { display: inline-flex; }
.flex-col { flex-direction: column; }
.flex-col-rev { flex-direction: column-reverse; }
.flex-break-row { flex-basis: 100%; height: 0; }
.flex-break-col { flex-basis: 100%; width: 0; }
.flex-row { flex-direction: row; }

.flex-grow-1 { flex: 1; }
.flex-grow-2 { flex: 2; }
.flex-grow-4 { flex: 4; }
.flex-grow-5 { flex: 5; }

.flex-shrink-0 { flex-shrink: 0; }
.flex-shrink-1 { flex-shrink: 1; }

.flex-basis-24 { flex: 0 0 24px; }
.flex-basis-50 { flex-basis: 50%; }
.flex-basis-60 { flex-basis: 60%; }
.flex-basis-70 { flex-basis: 70%; }
.flex-basis-auto { flex-basis: auto; }
.flex-basis-fit-content { flex-basis: fit-content; }
.flex-30 { flex: 0 0 33%; }
.flex-50 { flex: 0 0 50%; }
.flex-100 { flex: 0 0 100%; }
.flex-auto {flex: auto;}

.flex-wrap { flex-wrap: wrap; }

.align-stretch { align-items: stretch; }
.align-center { align-items: center; }
.align-end { align-items: flex-end; }
.align-content-center { align-content: center; }
.align-content-end { align-content: flex-end; }
.align-start { align-content: flex-start; }
.align-self-center { align-self: center; }
.align-self-end { align-self: end; }

.justify-center { justify-content: center; }
.justify-between {justify-content: space-between; }
.justify-around { justify-content: space-around; }
.justify-start { justify-content: start; }
.justify-end { justify-content: flex-end; }
.justify-flex-start { justify-content: flex-start; }

.min-height { min-height: 0px; }
.min-height-30 { min-height: 30%; }
.min-height-50 { min-height: 50%; }
.min-height-75 { min-height: 75%; }
.min-height-80 { min-height: 80%; }
.min-height-min-content { min-height: min-content; }

.min-width { min-width: 0px; }
.min-width-15 { min-width: 15%; }
.min-width-20 { min-width: 20%; }
.min-width-30 { min-width: 30%; }
.min-width-50 { min-width: 50%; }
.min-width-75 { min-width: 75%; }
.min-width-80 { min-width: 80%; }
.min-width-90 { min-width: 90%; }
.min-width-50px { min-width: 50px; }
.min-width-150px { min-width: 150px; }
.min-width-200px { min-width: 200px; }
.min-width-250px { min-width: 250px; }
.min-width-min-content { min-width: min-content; }

.max-height { max-height: 0px; }
.max-height-30 { max-height: 30%; }
.max-height-50 { max-height: 50%; }
.max-height-80 { max-height: 80%; }
.max-height-100 { max-height: 100%; }

.max-width { max-width: 0px; }
.max-width-25 { max-width: 25%; }
.max-width-30 { max-width: 30%; }
.max-width-50 { max-width: 50%; }
.max-width-80 { max-width: 80%; }
.max-width-90 { max-width: 90%; }
.max-width-95 { max-width: 95%; }
.max-width-100 { max-width: 100%; }

.max-width-80vw { max-width: 80vw; }
.max-height-80vh { max-height: 80vh; }

/* CURSOR ====================================================================*/
.cursor-pointer { cursor: pointer; }

.pointer-none { pointer-events: none; }
.pointer-all { pointer-events: all; }
.pointer-auto { pointer-events: auto; }

/* OTHERS ====================================================================*/
.opacity-0 {
  opacity: 0%;
}

.opacity-50 {
  opacity: 50%;
}

.no-wrap {
  white-space: nowrap;
}

.pre-line {
  white-space: pre-line;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inherit { 
  display: inherit; 
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

/* .hover-dark-filter:hover {
  filter: brightness(50%);
} */

.dark-gradient-filter:after {
  content: "";
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, transparent 0, rgba(0, 0, 0, 0.514));
}

.brightness-50 {
  filter: brightness(50%);
}
.brightness-100 {
  filter: brightness(100%);
}

.bg-cover { background-size: cover; }
.bg-no-repeat { background-repeat: no-repeat; }
.bg-center { background-position: center; }

/* CUSTOM ====================================================================*/
.triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid white;
  margin-top: -1px;
  margin-bottom: 1px;
}

.toggle-switch-thumb {
  position: absolute;
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.toggle-switch-thumb.switch-on {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.abs-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bottom-4px {
  bottom: 4px;
}

.right-4px {
  right: 4px;
}

.placeholder-text-bold::placeholder {
  font-weight: bold;
}

/* TRANSITION ================================================================*/
.animate-rotate svg {
  animation: rotate-cw 1.0s infinite;
}

@keyframes rotate-cw {
  0% { transform: rotate(0deg); }
  12% { transform: rotate(45deg); }
  24% { transform: rotate(90deg); }
  36% { transform: rotate(135deg); }
  50% { transform: rotate(180deg); }
  62% { transform: rotate(225deg); }
  74% { transform: rotate(270deg); }
  86% { transform: rotate(315deg); }
  100% { transform: rotate(360deg); }
}

.fade-out {
  animation: fadeOut ease 10s;
  animation-fill-mode: fowards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* HUD =======================================================================*/
.hud-bg {
    background-color: #2e2e2e;
    color: #bdbdcb;
}
.hud-bg {
    background-color: #2e2e2e;
    color: #bdbdcb;
}

.hud-roll {
    border: 2px solid #7c7c7c;
}

.hud-roll-mask {
    border: 30px solid #2e2e2e;
}

.hud-roll-fix {
    background-color: #73cf6b;
}

.hud-pitch-fix {
    background-color: #73cf6b;
}

.hud-roll-fix.danger {
    background-color: #e91429;
}

.hud-pitch-fix.danger {
    background-color: #e91429;
}

.hud-roll-marking {
    background-color: #7c7c7c;
}

.hud-pitch-marking {
    background-color: #7c7c7c;
}

.hud-pitch-marking-short {
    background-color: #7c7c7c;
}
.hud-roll {
    position: absolute;
    left: 9px;
    top: 9px;
    width: 140px;
    height: 70px;
    border-top-left-radius: 72px; /* 100px of height + 10px of border */
    border-top-right-radius: 72px; /* 100px of height + 10px of border */
    border-width: 2px;
    border-style: solid;
    border-bottom: 0px;
    transform-origin: 70px 70px;
}

.hud-roll-mask {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100px;
    height: 60px;
    border-top-left-radius: 100px; /* 100px of height + 10px of border */
    border-top-right-radius: 100px; /* 100px of height + 10px of border */
    border-width: 30px;
    border-style: solid;
    border-bottom: 0px;
}

.hud-roll-marking {
    position: absolute;
    height: 5px;
    width: 2px;
}

.hud-roll-fix {
    position: absolute;
    height: 10px;
    width: 2px;
    top: 0px;
    left: 80px;
}

.hud-pitch-fix {
    position: absolute;
    height: 2px;
    width: 100px;
    top: 80px;
    left: 30px;
}

.hud-pitch {
    height: auto;
    width: auto;
    transform-origin: center 80px;
    margin-left: 6px;
    margin-top: 10px;
}

.hud-pitch-marking {
    position: absolute;
    height: 3px;
    width: 50px;
}

.hud-pitch-marking-short {
    position: absolute;
    height: 3px;
    width: 30px;
}

.label-pin-arrow {
  position: absolute;
  transform: translate(-50%, -100%);
  width: 0; 
  height: 0; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #FFFBFE;
}

.label-pin {
  position: absolute;
  transform: translate(-50%, -100%);
  margin-top: -8px;
}

/* GridBox =======================================================================*/
.grid-2-cols {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-3-cols {
  display: grid;
  grid-template-columns: repeat(3, 30%);
}
.grid-area-1-3 {
  grid-area: auto / auto / span 1 / span 3;
}

.grid-gap-sm {
  grid-gap: 8px;
}

.grid-gap-md {
  grid-gap: 15px;
}

/* MAP =======================================================================*/
.task-marker-label {
  background-color: rgb(38, 109, 190);
  border: 2px solid white;
  z-index: 10;
}

.task-marker-label:hover {
  background-color: rgb(38, 109, 190);
  border: 2px solid  rgb(145, 202, 255);
}

.animate-fullscreen {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  background: red;
  transition: 1s ease-in-out;
}

.animate-scale {
  transition: 0.5s ease-in-out;
}

@media print {
  html, body, #root {
    background: none;
  }
  html, body, #root * {
    visibility: hidden;
  }
  #print-section-parent {
    overflow: visible;
  }
  #print-section, #print-section * {
    visibility: visible;
  }
  #print-section {
    background: none !important;
  }

  .no-print {
    display: none !important;
  }

  table, .avoid-break {
    page-break-inside: avoid;
  }

  .break-before-always {
    page-break-before: always;
  }

  .break-after-always {
    page-break-after: always;
  }

  .canvas-wrap {
    display: block;
    text-align: center;
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
}

/* PROGRESS BAR =======================================================================*/

.progress-bar {
  width: 100%;
  height: 30px;
  border-radius: 10px;
  background-color: #e6e6e6;
  margin-bottom: 10px;
}

.progress-bar-fill {
  height: 100%;
  border-radius: 10px;
  background-color: #404659;
  transition: width 0.5s ease-out;
}

@page {
  size: A4 portrait;
}
