.shadow {
  -webkit-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.50);
  -moz-box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.50);
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.50);
}

input[type='range'].bg-background::-webkit-slider-thumb {
  background-color: #1B1B1E;
  border: solid 1px rgb(230, 230, 230);	
}

.disabled,
.disabled svg { 
  color: rgba(55, 65, 81, 0.5);
  fill: rgba(55, 65, 81, 0.5);
}
/*
  COLORS NAMING SCHEME
  Color type without suffix is meant for text or icons (e.g. primary)
  Color type with -bg suffix is meant for background (e.g. primary-bg)
  Color type with on- prefix is meant for content that will be on that color
    type (e.g. on-primary is used on text that appears on a primary background)
  */
/* PRIMARY COLORS =========================================================== */
.primary { color: #B0C6FF; fill: #B0C6FF; }
.primary-border { border: solid 2px #2e79ff !important;}
.primary-bg { background-color: #B0C6FF; }
.on-primary { color: #002C72; fill: #002C72; }
.on-primary-bg { background-color: #002C72; }

.primary-container { background-color: #0040A0; }
.on-primary-container { color: #D9E2FF; }

.secondary { background-color: #C0C6DD; }
.on-secondary { fill: #2A3041; color: #2A3041; }

.secondary-container-bg { background-color: #404659; }
.on-secondary-container { fill: #DCE2FA; color: #DCE2FA; border-color: #DCE2FA; }
.on-secondary-container-bg { background-color: #DCE2FA; }

/* OTHER COLORS ============================================================= */
.root-bg { background-color: #1c1b22; }

.background { background-color: #141218; }
.background-bg { background-color: #141218; }
.background-opacity-12 { background-color: #1b1b1e85; }
.background-opacity-8 { background-color: #1b1b1eb2; }
.background-opacity-5 { background-color: #1b1b1ece; }
.on-background { fill: #E6E1E5; color: #E6E1E5; }
.on-background-bg { background-color: #E6E1E5; }

.outline { background-color: #8F909A; }
.outline-border { border: solid 2px #8F909A !important; }
.outline-warning-border { border: solid 1px #FF3B6B !important; }
.outline-border-top { border-top: solid 1px #8F909A !important; }
.outline-border-left{ border-left: solid 1px #8F909A !important;}
.outline-none { border: none !important; }
.outline-border-dotted { background-image: repeating-linear-gradient(0deg, #8f909a, #8f909a 9px, transparent 9px, transparent 12px, #8f909a 12px), repeating-linear-gradient(90deg, #8f909a, #8f909a 9px, transparent 9px, transparent 12px, #8f909a 12px), repeating-linear-gradient(180deg, #8f909a, #8f909a 9px, transparent 9px, transparent 12px, #8f909a 12px), repeating-linear-gradient(270deg, #8f909a, #8f909a 9px, transparent 9px, transparent 12px, #8f909a 12px); background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px; background-position: 0 0, 0 0, 100% 0, 0 100%; background-repeat: no-repeat;  }
.outline-border-hover:hover  { border: solid 2px #8F909A !important; }

.surface-dim-bg { background-color: #242424; }
.surface-bg { background-color: #242424; }
.surface-bright-bg { background-color: #3B383E; }
.surface-container-lowest-bg { background-color: #0F0D13; }
.surface-container-low-bg { background-color: #1D1B20; }
.surface-container-bg { background-color: #2f2f2f; }
.surface-container-high-bg { background-color: #2B2930; }
.surface-container-highest-bg { background-color: #36343B; }
.on-surface { color: #C5C6D0; fill: #C5C6D0; }
.on-surface-border { border: solid 1px #C5C6D0 !important; }

.surface-variant-bg { background-color: #44464E; }
.surface-variant-secondary-bg { background-color: #3d3e42; }
.surface-variant-border { border: solid 1px #44464E; }
.on-surface-variant { color: #C5C6D0; fill: #C5C6D0}
.on-surface-variant-bg { background-color: #C5C6D0; }
.on-surface-variant-border { border: solid 1px #86878f; }
.hover-surface-variant-bg { background-color: transparent; border-color: #8F909A }
.hover-surface-variant-bg:hover { background-color: #44464E; border-color: transparent }


.error { color: #FFB4A9; fill: #FFB4A9; }
.error-bg { background-color: #FFB4A9; }
.on-error { color: #680003; fill: #680003; }
.on-error-filter { filter: invert(11%) sepia(89%) saturate(5091%) hue-rotate(345deg) brightness(48%) contrast(116%); }

.error-container { background-color: #930006; }
.on-error-container { color: #FFDAD4; fill: #FFDAD4; }

.warning-container { background-color: rgb(97, 66, 15); }
.warning { color: rgb(231, 162, 43); }
.warning-border { border: solid 1px rgb(231, 162, 43) !important; }
.warning-text { color: rgb(231, 162, 43); fill: rgb(231, 162, 43); }

.success { background-color: #0c3f32; }
.success-border { border: solid 1px #178065 !important; }
.success-text { color: #3fbe9f; fill: #2fb895; }
.on-success { color: #2fb895; }

.on-no-anode { background-color: #FF3B6B; fill: white; }
.on-anode { background-color: #25262f; }

.border-transparent { border-color: transparent; }
.border-white { border-color: white; }
.border-blue { border-color: #4c76cc; }
.prompt-text { color: #FF3B6B;; }

.hashtag-text { color: #f5f6f8 }
.hashtag-background { background-color: #333 }

/* TEXT COLORS ============================================================== */
.text-white {
  color: #ececec;
}

.text-white svg {
  color: #ececec;
}

.text-green-500 {
  color: rgba(16, 185, 129, 1);
}

.text-green-500 svg {
  fill: rgba(16, 185, 129, 1);
}

/* FIXED COLORS ============================================================= */
.white { color: #f9f9f9; fill: #f9f9f9; }
.white-bg { background-color: #f9f9f9; }
.on-white { color:rgb(27, 27, 27, 1); fill: rgb(27, 27, 27, 1); }
.on-white-bg { background-color:rgb(27, 27, 27, 1); }

.black { color: #000; fill: #000; }
.black-bg { background-color: #000; }

/* BG COLORS ================================================================ */
.bg-black-6 {
  background-color: rgba(0, 0, 0, 0.6);
}

.bg-black-9 {
  background-color: rgba(0, 0, 0, 0.9);
}

.bg-red {
  background-color: red;
}

.bg-blue {
  background-color: #1B49A3;
}

.bg-transparent {
  background-color: transparent;
}
.color-transparent {
  color: transparent;
}

/* SLIDER ================================================================= */
input[type='range'] {
  background-color: #44464E;
  background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #B0C6FF),
      color-stop(100%, #B0C6FF)
  );
}

input[type='range']::-webkit-slider-thumb {
  background-color: #B0C6FF;
}

/* SCROLLBAR ============================================================== */
::-webkit-scrollbar-thumb {
  background-color: #C5C6D0;
}

.ruler-label {
  background-color: #176114;
  /* color: #8eff8a; */
  color: #D9E2FF;
}

/* SCROLLBAR ============================================================== */
.no-fill {
  fill: none;
}