.dropdown {
  width: 100%;
  height: 100%;
  position: relative;
}

.dropdown-list {
  position: absolute;
  top: 48px;
  width: 100%;
  max-height: 200px;
  padding: 12px 12px;
  background-color: #33343B;
  box-sizing: border-box;
  overflow-y: auto !important;
}

.minimal-dropdown-list {
  position: absolute;
  top: 45px;
  max-height: 200px;
  background-color: #33343B;
  box-sizing: border-box;
  overflow-y: auto !important;
}

.dropdown-option {
  cursor: pointer;
  border-radius: 8px;
  padding: 6px 12px;
  text-overflow: clip;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.dropdown-option:hover {
  background-color: #494a53;
}

.dropdown-button {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 4px 12px;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #33343B;
  box-sizing: border-box;
}