.thumbnail {
    justify-content: center;
    align-items: center;
    border-style: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    border-style: hidden;
    padding: 0px;
    border-radius: 20px;
    width: 100%;
    background-color: transparent;
}

.thumbnail-unselected:hover {
    filter: brightness(50%);
}

.thumbnail .icon {
    position: absolute;
    right: 5%;
    bottom: 5%;
    width: 10%;
    padding: 5px;
    border-radius: 50%;
    border-style: solid;
    border-color: white;
    background-color: #1B49A3;
    color: white;
}