.square-button {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px;
  width: 100px;
}

.square-button:hover {
  cursor: pointer;
}

.square-button .icon {
  width: 34px;
  height: 34px;
  padding: 8px;
  background-color: #33343B;
  border-radius: 14px;
  margin-bottom: 8px;
}

.square-button:hover .icon {
  background-color: #43454e;
}

.square-button:hover .icon {
  box-shadow: 0 0 0 2pt #4574b6;
}