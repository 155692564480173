@keyframes slideInFromRight {
  0% { transform: translateX(50px); opacity: 0; }
  100% { transform: translateX(0px); opacity: 1; }
}

.animate-fade-in {
  animation: slideInFromRight 0.5s;
}

@keyframes slideOutToRight {
  0% { transform: translateX(0px); opacity: 1; }
  100% { transform: translateX(50px); opacity: 0; }
}

.animate-fade-out {
  animation: slideOutToRight 0.5s;
  transform: translateX(50px); opacity: 0;
}

.img-hover:hover {
    -webkit-transform:scale(1.5); /* or some other value */
    transform:scale(1.5);
}

@keyframes slideInFromBottom {
  0% { transform: translateY(50px); opacity: 0; }
  100% { transform: translateY(0px); opacity: 1; }
}

.animate-fade-in-bottom {
  animation: slideInFromBottom 0.5s;
}

@keyframes slideOutToBottom {
  0% { transform: translateY(0px); opacity: 1; }
  100% { transform: translateY(50px); opacity: 0; }
}

.animate-fade-out-bottom {
  animation: slideOutToBottom 0.5s;
  transform: translateY(50px); opacity: 0;
}