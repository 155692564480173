.primary-light { color: #2459BC; fill: #2459BC; }
.primary-light-bg { background-color: #2459BC; }
.primary-light-hover:hover { color: #2459BC; fill: #2459BC; }

.secondary-light { color: #DCE2FA; fill: #DCE2FA; }
.secondary-light-bg { background-color: #DCE2FA; }
.on-secondary-light { fill: #151B2C; color: #151B2C; }
.on-secondary-light-bg { fill: #151B2C; color: #151B2C; }

.surface-variant-light { color: #E2E2EC; fill: #E2E2EC; }
.surface-variant-light-bg { background-color: #E2E2EC }
.surface-variant-light-border { border: solid 1px #a5a5ad; }

.on-surface-variant-light { color: #44464E; fill: #44464E; }
.on-surface-variant-light-bg { background-color: #44464E; }

.on-warning-light { fill: rgb(65, 41, 0); color: rgb(65, 41, 0); }
.warning-light-bg { background-color: rgb(255, 207, 124); }