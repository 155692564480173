.toggle-switch-options {
    position: relative;
    width: fit-content;
    min-width: 180px;
    display: flex;
    flex-wrap: wrap;
    border-width: 2px;
    border-color: #BFBFBF;
    border-radius: 15px;
    border-style: solid;
}

.toggle {
    margin: 3px;
    width: 25%;
    padding: 5px 10px 5px 10px;
    text-align: center;
    flex-grow: 1;
    border-radius: 13px;
    font-weight: bold;
}

.toggle-active {
    background-color: #1B49A3;
    color: white;
}

.toggle-inactive {
    color: #BFBFBF;
}