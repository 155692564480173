.player-bar {
  width: 100%;
  height: 42px;
  background-color: #17171d;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.player-bar .tooltip {
  position: absolute;
  top: -12px;
  z-index: 3;
  padding: 4px 8px;
  font-size: 12px;
  background-color: rgb(54, 54, 54);
  border-radius: 4px;
  margin-left: -24px;
  visibility: hidden;
}

.player-bar .marker-tooltip {
  position: absolute;
  top: -37px;
  z-index: 3;
  padding: 4px 8px;
  font-size: 12px;
  background-color: rgb(54, 54, 54);
  border-radius: 4px;
  visibility: hidden;
}

.player-bar .slider {
  flex: 1;
  -webkit-appearance: none;
  appearance: none;
  background-color: #202025;
  overflow: visible;
  border-radius: 20px;
  height: 14px;
  display: flex;
  position: relative;
  cursor: pointer;
}

.player-bar .buffer {
  position: absolute;
  border-radius: 20px;
  height: 100%;
  background-color: rgb(51, 51, 51);
}

.player-bar .seeker-bar {
  border-radius: 20px;
  height: 14px;
  background-color: #1746A5;
  pointer-events: none;
  z-index: 1;
}

.player-bar .seeker-thumb {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #83acff;
  pointer-events: none;
  margin-left: -7px;
  z-index: 2;
}

.player-bar .marker {
  height: 200%;
  width: 4px;
  position: absolute;
  background-color: #E6E1E5;
  z-index: 1;
  top: -50%;
}