.toggle-switch {
  position: relative;
  display: flex;
  height: 20px;
  width: 34px;
}

.toggle-switch-track {
  height: 16px;
  width: 34px;
  margin-top: 2px;
  border-radius: 8px;
  background-color: rgb(202, 202, 202);
}

.toggle-switch-thumb {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  background-color: rgb(236, 236, 236);
}

.toggle-switch-thumb.on {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
  background-color: #2b78fe;
}

.toggle-switch-track.on {
  background-color: #a6c6ff;
}
