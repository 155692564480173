.map-toolbar {
  position: absolute;
  bottom: 24px;
  right: 24px;
  z-index: 2;
}

.map-toolbar .button {
  background-color: #25262F;
  padding: 6px;
  fill: #BFBFBF;
  z-index: 2;
}

.map-toolbar .button:hover {
  background-color: #33343B;
}

.map-toolbar-panel {
  display: flex;
  position: absolute;
  padding: 12px 18px;
  right: 48px;
  bottom: 0px;
  width: 320px;
  font-size: 16px;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 14px;
}